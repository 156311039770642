<template>
  <div data-sel="page-cntr">
    <CSpinner v-if="loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <CRow class="add-button-row">
      <CButton
          data-sel="btn-add-cntr"
          variant="outline"
          color="info"
          @click="showTemplates = !showTemplates">
        Add Contract
      </CButton>
      <CCollapse
          :show="showTemplates"
          :duration="1">
        <CButtonGroup>
          <CButton
              data-sel="btn-add-cntr-custom"
              @click="openAddModal('empty')"
          >
            Custom
          </CButton>
          <CButton
              data-sel="btn-add-cntr-regio"
              @click="openAddModal('regio')"
          >
            Regio
          </CButton>
          <CButton
              data-sel="btn-add-cntr-tc"
              @click="openAddModal('town_and_country')"
          >
            T&C
          </CButton>
          <CButton
              data-sel="btn-add-cntr-ytong"
              @click="openAddModal('ytong')"
          >
            Ytong
          </CButton>
          <CButton
              data-sel="btn-add-cntr-massivhaus"
              @click="openAddModal('massivhaus')"
          >
            Massivhaus
          </CButton>
          <CButtonClose
              @click="showTemplates = !showTemplates">
          </CButtonClose>
        </CButtonGroup>
      </CCollapse>
    </CRow>
    <ContractAdd
        v-bind:contractTemplate="selectedTemplate"
        v-on:add-item="addItemLocal"
    />
    <CCardBody>
      <CDataTable
        :items="items"
        :fields="fields"
        table-filter
        items-per-page-select
        :items-per-page="itemsPerPage"
        v-on:update:sorter-value="updateOrder"
        v-on:update:table-filter-value="updateFilter"
        v-on:pagination-change="updatePagination"
        hover
        sorter
        :pagination="false"
      >
        <template #edit_item="{item}">
          <td class="py-2">
            <TableButtonGroup
                v-on:view="viewItem(item)"
                v-on:edit="editItem(item)"
                v-on:delete-prompt="toggleDeleteItemModal(item)"
            />
            <DeleteModal
                entity="contract"
                :name="deleteItemName"
                :nid="deleteItemID"
                :show.sync="showDeleteItemModal"
                v-on:delete="deleteItem(item)"
                v-on:cancel-delete="toggleDeleteItemModal(item)"
            />
          </td>
        </template>
        <template #status="{item}">
          <td>
            <CBadge
                :color="getBadge(item.isValid)"
            >{{item.isValid ? 'Validated': 'Not validated'}}</CBadge>
          </td>
        </template>
      </CDataTable>
      <CPagination
          :activePage.sync="activePage"
          :pages="pages"
      />
    </CCardBody>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment'
import DeleteModal from "@/components/common/DeleteModal";
import TableButtonGroup from "@/components/common/TableButtonGroup";
import ContractAdd from "@/apps/contracts/components/ContractAdd";
import ContractTemplates from "@/apps/contracts/templates/contract_templates";

const queryContracts = gql`
query contracts($offset: Int, $limit: Int, $order: String, $q: String) {
  contracts(offset: $offset, limit: $limit, order: $order, q: $q) {
    totalCount
    data {
      nid
      isValid
      client {
        nid
        name
        accountOwner{
          nid
          firstName
          lastName
        }
      }
      entity
      domain
      signingDate
      startTerm
      endTerm
      specialCancellationRight
      updatedAt
    }
  }
}
`
const deleteContract = gql`
  mutation deleteContract($nid: ID!){
    deleteContract(nid: $nid)
  }
`

const fields = [
    {
      key: 'edit_item',
      label: '',
      _style: 'width:1%',
      sorter: false,
      filter: false
    },
    { key: 'status', _style: 'width:1%', filter: true},
    { key: 'nid', label: 'NID', _style:'min-width:10px;width:1%' },
    { key: 'entity', _style:'min-width:100px;width:5%' },
    { key: 'client', _style:'min-width:200px;width:10%' },
    { key: 'domainLabel', label: 'Domain', _style:'min-width:150px;width:10%'},
    { key: 'signingDate', _style:'min-width:120px;width:5%' },
    { key: 'startTerm', _style:'min-width:120px;width:5%' },
    { key: 'endTerm', _style:'min-width:120px;width:5%' },
    { key: 'specialCancellationRight', _style:'min-width:120px;width:5%' },
    { key: 'accountOwner', sorter: false, label: 'Account Owner', _style:'min-width:120px;width:5%' },
    { key: 'updatedAt', label: 'Last Updated', _style:'min-width:50px' },
  ]

export default {
  name: "Contracts",
  components: {
    ContractAdd,
    DeleteModal,
    TableButtonGroup
  },
  data() {
    return {
      waiting: false,
      contracts: {},
      orderColumn: "updatedAt",
      orderPrefix: "-",
      tableFilter: null,
      itemsPerPage: 20,
      queryOffset: 0,
      activePage: 1,
      error: null,
      fields: fields,
      showDeleteItemModal: false,
      deleteItemID: null,
      deleteItemName: null,
      showTemplates: false,
      selectedTemplate: null,
      domainOptions: {
        fertighaus_de: "fertighaus.de",
        fertighaus_at: "fertighaus.at",
        fachwerkhaus_de: "fachwerkhaus.de",
        baumarkt_de: "baumarkt.de",
        baddesign_de: "baddesign.de",
        traumbad_de: "traumbad.de",
        fliesenhandel_de: "fliesenhandel.de",
        luxus_fertighaus_de: "luxus-fertighaus.de",
        baumarkt_shopping_de: "baumarkt-shopping.de",
        kachelofen_online_de: "kachelofen-online.de",
        das_baulexikon_de: "das-baulexikon.de",
        handwerkersuche_de: "handwerkersuche.de",
        massivhaus_de: "massivhaus.de",
        einfamilienhaus_de: "einfamilienhaus.de",
        bungalow_de: "bungalow.de",
        immowelt_de: "immowelt.de"
      }
    }
  },
  methods: {
    updateOrder(event){
      if(event.column==='domainLabel'){
        this.orderColumn = 'domain';
      } else if (event.column === 'client') {
        this.orderColumn = 'client.name';
      } else {
        this.orderColumn = event.column;
      }
      this.orderPrefix = event.asc ? '': '-';
    },
    updateFilter(event){
      this.tableFilter = event;
    },
    updatePagination(event){
      this.itemsPerPage = event;
    },
    openAddModal(template){
      this.$store.commit('set', ['allowEdit', true]);
      this.selectTemplate(template);
      this.$store.commit('set', ['addModalShow', true]);
    },
    getItemName(item){
      return item.name
    },
    editItem(item){
      this.$store.commit('set', ['allowEdit', true]);
      this.$router.push({ path: `/contracts/contract/${item.nid}`});
    },
    viewItem(item){
      this.$store.commit('set', ['allowEdit', false]);
      this.$router.push({ path: `/contracts/contract/${item.nid}`});
    },
    toggleDeleteItemModal(item){
      this.deleteItemID = item.nid;
      this.deleteItemName = this.getItemName(item);
      this.showDeleteItemModal = !this.showDeleteItemModal
    },
    deleteItem(item){
      this.$apollo.mutate({
        mutation: deleteContract,
        variables: {
          nid: this.deleteItemID
        },
        error(error){
          console.error(error);
          this.error = error.message;
          this.deleteItemID = null;
          this.deleteItemName = null;
          this.showDeleteItemModal = false;
        }
      })
      .then(res => {
        this.waiting = true;
        setTimeout(()=> {
          this.$apollo.queries.contracts.refetch(() => {
            return {
              offset: this.offset,
              limit: this.itemsPerPage,
              order: this.sortOrder,
              q: this.tableFilter
            }
          })}, 500);
        this.waiting = false;
        this.deleteItemID = null;
        this.deleteItemName = null;
        this.showDeleteItemModal = false;
      })
    },
    updateItemLocal(data){
      this.contracts.data = this.contracts.data.filter(function(item) {return item.nid === data.nid ? data : item });
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    addItemLocal(item){
      this.contracts.data.pop();
      this.contracts.data = [item].concat(this.contracts.data);
    },
    fetchData(){
      if(this.doRefetch){
        this.queryOffset = 0
        this.tableFilter = null
        this.orderPrefix = '-'
        this.orderColumn = 'updatedAt'
        this.waiting = true
        setTimeout(()=> {
          this.$apollo.queries.contracts.refetch(() => {
            return {
              offset: this.offset,
              limit: this.itemsPerPage,
              order: this.sortOrder,
              q: this.tableFilter
          }});
        }, 500);
        this.waiting = false;
        this.$store.commit('set', ['refetchData', false]);
      }
    },
    getBadge(isValid){
      return isValid ? 'success': 'warning'
    },
    selectTemplate(template){
      this.showTemplates = !this.showTemplates;
      this.selectedTemplate = ContractTemplates[template];
    }
  },
  apollo: {
    contracts: {
      query: queryContracts,
      variables(){
        return {
          offset: this.offset,
          limit: this.itemsPerPage,
          order: this.sortOrder,
          q: this.tableFilter
        }
      },
      error(error) {
        console.error(error);
        this.error = error.message;
      }
    }
  },
  computed:{
    doRefetch(){
      return this.$store.state.refetchData
    },
    loading(){
      return this.$apollo.queries.contracts.loading || this.waiting
    },
    sortOrder: {
      get() {
        return `${this.orderPrefix}${this.orderColumn}`
      },
      set(){

      }
    },
    pages:{
      get() {
        return Math.ceil(this.contracts.totalCount / this.itemsPerPage)
      },
      set(){

      }
    },
    offset:{
      get() {
        return this.itemsPerPage*(this.activePage-1)
      },
      set(){

      }
    },
    items () {
      if(this.contracts.data){
        return this.contracts.data.map(item => {
          return {
            ...item,
            signingDate: item.signingDate?item.signingDate : '',
            startTerm: item.startTerm? item.startTerm : '',
            endTerm: item.endTerm ? item.endTerm : '',
            specialCancellationRight: item.specialCancellationRight? item.specialCancellationRight : '',
            domainLabel: this.domainOptions[item.domain],
            client: item.client.name,
            accountOwner: item.client.accountOwner? item.client.accountOwner.firstName + ' ' + item.client.accountOwner.lastName: '',
            updatedAt: item.updatedAt? moment(String(item.updatedAt)).format('YYYY-MM-DD HH:mm') : '',
          }
        })
      } else {
        return []
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchData())
  },
}
</script>

<style scoped>
  .add-button-row{
    justify-content: start;
    margin-left: 1.25rem;
  }
</style>
