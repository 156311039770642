<template>
  <div>
    <CModal
        data-sel="mdl-add-cntr"
        title="New Contract"
        size="xl"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          data-sel="mdl-add-cntr-error"
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError"
      >
        {{error}}
      </CAlert>
      <ContractForm
          v-bind:contractIn="contractTemplate"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import ContractForm from "@/apps/contracts/components/ContractForm";

const createContract = gql`
  mutation createContract($contract: ContractInput){
    createContract(contract: $contract) {
      nid
      isValid
      client {
        nid
        name
        accountOwner{
          nid
          firstName
        }
      }
      domain
      signingDate
      startTerm
      endTerm
      specialCancellationRight
    }
  }
`

export default {
  name: "ContractAdd",
  props: ["contractTemplate"],
  components: {
    ContractForm
  },
  data(){
    return {
      contract: {},
      contractSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      data.client = data.client? {nid: data.client.nid}: null;
      data.positions = data.positions.map(item => {
        delete item.idx
        item.product = {nid: item.product.nid}
        //change data types
        item.numberOfItems = item.numberOfItems!==undefined && item.numberOfItems!==null? parseFloat(item.numberOfItems): null;
        item.pricePerItem = item.pricePerItem!==undefined && item.pricePerItem!==null? parseFloat(item.pricePerItem): null;
        item.ppcRate = item.ppcRate!==undefined && item.ppcRate!==null? parseFloat(item.ppcRate): null;
        item.pricePerItem = item.pricePerItem!==undefined && item.pricePerItem!==null? parseFloat(item.pricePerItem): null;
        item.ppcAmount = item.ppcAmount!==undefined && item.ppcAmount!==null? parseFloat(item.ppcAmount): null;
        return item
      });
      this.contractSave = data;
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createContract,
          variables: {
            contract: this.contractSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createContract);
          this.contract = {};
          this.contractSave = {};
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.contract = {};
        this.contractSave = {};
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
