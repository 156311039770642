// Templates for standard contracts
function firstOfNextMonth(){
  var d = new Date();
  d.setMonth(d.getMonth()+1, 1);
  return d.toISOString().split('T')[0];
}

const ContractTemplates = {
  empty: {
    "positions": [],
    "entity": "PW",
    "domain": "fertighaus_de",
    "automaticContractRenewal": "yes_months_12",
    "cancellationPeriod": "months_3_eoc",
    "invoiceDate": "month_start",
    "sepa": true,
    "paymentTarget": "days_10",
    "startContract": firstOfNextMonth(),
    "startTerm": firstOfNextMonth(),
    "endTerm": null
  },
  // Example: 13397
  regio: {
    "positions": [
      {
        "product": {
          "nid": "gdqr9mnl",
          "name": "Branding Standard - regio offer 2020"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 239,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "quarterly",
        "notes": null
      },
      {
        "product": {
          "nid": "bmnkt8pt",
          "name": "Leads Optimiert - Maximalmenge",
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 17,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 10,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      }
    ],
    "entity": "PW",
    "domain": "fertighaus_de",
    "automaticContractRenewal": "yes_months_12",
    "cancellationPeriod": "months_3_eoc",
    "invoiceDate": "month_start",
    "sepa": true,
    "paymentTarget": "days_10",
    "startContract": firstOfNextMonth(),
    "startTerm": firstOfNextMonth()
  },
  // Example: 13399
  town_and_country: {
    "positions": [
      {
        "product": {
          "nid": "gdqr9mnl",
          "name": "Branding Standard - regio offer 2020",
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 239,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "months_3",
        "notes": null
      },
      {
        "product": {
          "nid": "4r38tabh",
          "name": "Add on regio offer 2020: Top Platzierung Anbietersucher",
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 0,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "bmnkt8pt",
          "name": "Leads Optimiert - Maximalmenge"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 19,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 20,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      }
    ],
    "entity": "PW",
    "domain": "fertighaus_de",
    "automaticContractRenewal": "yes_months_12",
    "cancellationPeriod": "months_3_eoc",
    "invoiceDate": "month_start",
    "sepa": true,
    "paymentTarget": "days_10",
    "startContract": firstOfNextMonth(),
    "startTerm": firstOfNextMonth()
  },
  // Example: 13398
  ytong: {
    "positions": [
      {
        "product": {
          "nid": "gdqr9mnl",
          "name": "Branding Standard - regio offer 2020"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 239,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "gdqr9mnl",
          "name": "Branding Standard - regio offer 2020"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": -24,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": true,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "3sbeiebi",
          "name": "Add on regio offer 2020: Top Platzierung Haussuche"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 49,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "3sbeiebi",
          "name": "Add on regio offer 2020: Top Platzierung Haussuche"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": -49,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": true,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "4r38tabh",
          "name": "Add on regio offer 2020: Top Platzierung Anbietersucher"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 49,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "4r38tabh",
          "name": "Add on regio offer 2020: Top Platzierung Anbietersucher"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": -49,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": true,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "bmnkt8pt",
          "name": "Leads Optimiert - Maximalmenge"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 17,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 20,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "bmnkt8pt",
          "name": "Leads Optimiert - Maximalmenge"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": -2,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 20,
        "isReimbursement": false,
        "isDiscount": true,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "bijfm5br",
          "name": "Leads Optimiert - Umverteilung mit Beratungswunsch"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 0,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 2,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "c7mrfxyz",
          "name": "Leads Optimiert - Umverteilung ohne Beratungswunsch"
        },
      "startTerm": firstOfNextMonth(),
      "endTerm": null,
      "pricePerItem": 0,
      "ppcRate": null,
      "ppcAmount": null,
      "numberOfItems": 1,
      "isReimbursement": false,
      "isDiscount": false,
      "invoiceCycle": "monthly",
      "notes": null
      }
    ],
    "entity": "PW",
    "domain": "fertighaus_de",
    "automaticContractRenewal": "yes_months_12",
    "cancellationPeriod": "months_3_eoc",
    "invoiceDate": "month_start",
    "sepa": true,
    "paymentTarget": "days_10",
    "startContract": firstOfNextMonth(),
    "startTerm": firstOfNextMonth()
  },

  massivhaus: {
    "positions": [
			{
        "product": {
          "nid": "tzeiaa7n",
          "name": "MS - Anbieter Portrait und Hausprofile"
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 0,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 1,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      },
      {
        "product": {
          "nid": "ykx2y2sb",
          "name": "MS - Leads - Maximalmenge",
        },
        "startTerm": firstOfNextMonth(),
        "endTerm": null,
        "pricePerItem": 15,
        "ppcRate": null,
        "ppcAmount": null,
        "numberOfItems": 9999,
        "isReimbursement": false,
        "isDiscount": false,
        "invoiceCycle": "monthly",
        "notes": null
      }
    ],
    "entity": "MS",
    "domain": "massivhaus_de",
    "automaticContractRenewal": "no",
    "cancellationPeriod": "daily",
    "invoiceDate": "month_start",
    "sepa": false,
    "paymentTarget": "days_7",
    "startContract": firstOfNextMonth(),
    "startTerm": firstOfNextMonth()
  },
};

export default ContractTemplates;